import { Component, DoCheck, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services';
import { TokenService } from './services/token.service';

@Component({
  selector: 'haven-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, DoCheck {
  isHeaderVisible: boolean = false;
  title = 'HAVEN-AdminPortal';
  constructor(
    private router: Router,
    private authService: AuthService,
    private tokenService: TokenService
  ) {}
  ngOnInit() {
    this.authService.getSubject().subscribe((result) => {
      this.isHeaderVisible = true;
    });
  }
  ngDoCheck(): void {
    this.startup();
  }
  idleTimeExpired() {
    this.authService.logout();
  }
  startup() {
    if (
      this.router.url.indexOf('login') === -1 &&
      this.router.url.indexOf('changepassword') === -1 &&
      this.router.url.indexOf('setpassword') === -1 &&
      this.router.url !== '/'
    ) {
      this.isHeaderVisible = true;
    } else {
      this.isHeaderVisible = false;
    }
  }

  @HostListener('click', ['$event'])
  customEventHandler(event: any) {
    {
      if (
        this.router.url.indexOf('login') === -1 &&
        this.router.url.indexOf('setpassword') === -1
      ) {
        this.tokenService.checkTokenValidity();
      }
    }
  }
}
