import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { configJSON } from '../appURLConfig';

@Injectable({
  providedIn: 'root'
})
export class SehedulingService {

  constructor( private http: HttpClient) { }

  getSchedulingInfo(data: any): Observable<any> {
    return this.http.post(configJSON.getSchedulingData, data,{withCredentials: true});
  }
  updateScheduling(data: any): Observable<any> {
      return this.http.post(configJSON.updateSchedulingData, data,{withCredentials: true});
    }
}
