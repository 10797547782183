<div class="ngx-timepicker-field-example">
  <input
    [ngxTimepicker]="appendedToInput"
    class="form-control time-control"
    readonly
    [(ngModel)]="setTime"
    (ngModelChange)="onTimeChanged($event)"
  />
  <ngx-material-timepicker
    #appendedToInput
    [appendToInput]="true"
    [theme]="customTheme"
  ></ngx-material-timepicker>
</div>
