import { Component, DoCheck, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import appConstant from '../../assets/app-text.json';
import pwdRules from '../../assets/pwd-rules.json';
import { ResponseMessageType } from '../common/enum/responseMessageType';
import { ChangePasswordModel, ResponseModel } from '../common/models';
import Utils from '../common/utils/app.utils';
import { MustMatch } from '../common/validators/must-match.validator';
import { validatePassword } from '../common/validators/password-validator.directive';
import { AuthService, LoginService, NotificationService } from '../services';
@Component({
  selector: 'haven-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, DoCheck {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {}
  submitted = false;
  passwordRegistrationForm: FormGroup;
  appConstant;
  pwdRules;
  userEmail = '';
  isLoading = false;
  isShowForm = false
  passwordRules = {
    validateMinLength: false,
    validateMaxLength: false,
    validateLowerCase: false,
    validateUpperCase: false,
    validateNumeric: false,
    validateNonAlphaNumeric: false,
  };
  ngOnInit() {
    this.appConstant = appConstant;
    this.pwdRules = pwdRules;
    document.documentElement.setAttribute('class', 'chng-pwd-Html');
    this.userEmail = this.authService.getUserEmail();
    this.passwordRegistrationForm = this.formBuilder.group(
      {
        password: ['', [validatePassword()]],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
    this.passwordRegistrationForm
      .get('password')
      .valueChanges.subscribe((value) => {
        this.passwordRules = {
          validateMinLength: !!value && /^(?=.*).{8,}$/.test(value),
          validateMaxLength: !!value && /^(?=.*).{8,15}$/.test(value),
          validateLowerCase: !!value && /^(?=.*[a-z])/.test(value),
          validateUpperCase: !!value && /^(?=.*[A-Z])/.test(value),
          validateNumeric: !!value && /^(?=.*[0-9])/.test(value),
          validateNonAlphaNumeric:
            !!value && /^(?=.*[!@#\$%\^&\*])/.test(value),
        };
      });
  }
  ngDoCheck() {
    if (this.userEmail === '') {
      this.userEmail = this.authService.getNewValidatedUserEmail();
      this.isShowForm = true;
    }
  }
  clearConfirmPassword() {
    this.pwdRegForm.confirmPassword.reset();
  }
  clearPassword() {
    this.pwdRegForm.password.reset();
  }
  get pwdRegForm() {
    return this.passwordRegistrationForm.controls;
  }
  onSubmit() {
    this.isLoading = true;
    this.submitted = true;
    if (this.passwordRegistrationForm.valid) {
      const req = new ChangePasswordModel();
      req.userEmailId = this.authService.getchangePasswordData().primaryEmailAddress;
      req.password = Utils.encryptData(this.pwdRegForm.password.value);
      req.encryptedUserId = this.route.snapshot.queryParamMap.get('params');

      this.loginService.savePassword(req).subscribe(
        (response: ResponseModel) => {
          if (document.cookie && document.cookie !== '') {
            this.authService.clearCookies();
          }
          if (!!response) {
            if (
              response.message === ResponseMessageType.SUCCESS && response.data &&
              response.data.isPasswordUpdated
            ) {
              this.passwordRegistrationForm.reset();
              this.notificationService.success(appConstant.PWD_SAVED_MESSAGE);
              this.authService.clearCookies();
              this.router.navigate(['/home']);
            }
          }
          this.isLoading = false;
        },
        (error: any) => {this.isLoading = false;}
      );
    }
  }
}
