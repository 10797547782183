<mat-form-field appearance="fill">
  <input
    matInput
    #dateInput
    [placeholder]="placeHolderText"
    [matDatepicker]="picker"
    (dateChange)="change($event)"
    (click)="picker.open()"
    [value]="date"
    class="form-control date-control"
    [min]="minDate"
    readonly />
  <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
  <mat-datepicker
    #picker
    disabled="false"
    [calendarHeaderComponent]="customHeader"
  ></mat-datepicker
></mat-form-field>
