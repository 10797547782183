import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { configJSON } from '../appURLConfig';
import {
  AuthenticateUserRequestModel,
  ChangePasswordModel,
  ForgotPasswordRequestModel,
  ResponseModel,
  UserResponseModel,
} from '../common/models';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}
  authenticateUser(
    request: AuthenticateUserRequestModel
  ): Observable<UserResponseModel> {
    return this.http.post<UserResponseModel>(
      configJSON.validateAdminUser,
      request,
      {withCredentials: true}
    );
  }
  savePassword(request: ChangePasswordModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(configJSON.updatePassword, request,{withCredentials: true});
  }

  forgotPassword(
    request: ForgotPasswordRequestModel
  ): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(configJSON.forgotPassword, request,{withCredentials: true});
  }

  logout(): Observable<ResponseModel> {
    const requestBody = { };
    return this.http.post<ResponseModel>(configJSON.getLogoutUser, requestBody,{withCredentials: true});
  }
}
