import {
  Component,
  DoCheck,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import appConstant from '../../assets/app-text.json';
import { ResponseMessageType } from '../common/enum/responseMessageType';
import {
  AuthenticateUserRequestModel,
  ForgotPasswordRequestModel,
  UserLoginModel,
} from '../common/models/authenticateUserRequestModel';
import Utils from '../common/utils/app.utils';
import { AuthService, DataCenterListService, LoginService, NotificationService } from '../services';

@Component({
  selector: 'haven-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, DoCheck, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private dataCenterListService: DataCenterListService
  ) {}
  isEmailRendered: boolean = false;
  isLoading: boolean = false;

  appConstant;
  loginModel = new UserLoginModel();
  isValid: boolean = true;
  returnUrl: string;
  isFormSubmitted: boolean = false;

  @ViewChild('email', { read: ElementRef, static: true })
  emailField: ElementRef;
  // login form is form in html and does not belongs to any specific type
  @ViewChild('loginForm') loginForm: any;
  ngOnInit() {
    this.authService.clearCookies();
    this.appConstant = appConstant;
    document.documentElement.setAttribute('class', 'loginHtml');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    this.dataCenterListService.clientDataCenterList = [];
    this.notificationService.getSubject().subscribe((result) => {
      if (result.isLoginError) {
        this.isLoading = false;
        this.loginForm.reset();
      }
    });
  }
  ngDoCheck(): void {
    if (!this.isEmailRendered) {
      this.isEmailRendered = !!this.emailField.nativeElement;
      if (this.isEmailRendered) {
        this.emailField.nativeElement.focus();
      }
    }
  }
  ngOnDestroy(): void {
    document.documentElement.removeAttribute('class');
  }
  forgotPassword() {
    this.isLoading = true;
    const data = new ForgotPasswordRequestModel();
    data.emailAddress = this.loginModel.email;
    this.authService.clearCookies();
    this.loginService.forgotPassword(data).subscribe(
      (result) => {
        this.isLoading = false;
        if (!!result) {
          if (result.message === ResponseMessageType.SUCCESS) {
            if (result.data.isEmailSent) {
              this.notificationService.success(result.data.message);
            }
          }
        }
        this.loginForm.reset();
        this.emailField.nativeElement.focus();
      },
      (error) => {
        this.isLoading = false;
        this.loginForm.reset();
        this.emailField.nativeElement.focus();
      }
    );
  }
  onSubmit() {
    this.isLoading = true;
    const data = new AuthenticateUserRequestModel();
    data.emailAddress = this.loginModel.email;
    data.password = Utils.encryptData(this.loginModel.password);
    this.authService.deleteCookies();
    this.loginService.authenticateUser(data).subscribe(
      (result) => {
        this.isLoading = false;
        if (!!result) {
          if (result.message === ResponseMessageType.SUCCESS) {
            this.authService.setAuthenticatedCookies(result.data);
            this.router.navigate([this.returnUrl]);
            this.authService.setSubject(result.data);
          }
        }
        this.loginModel.email = '';
        this.loginModel.password = '';
        this.loginForm.reset();
        this.emailField.nativeElement.focus();
      },
      (error) => {
        this.isLoading = false;
        this.loginForm.reset();
        this.emailField.nativeElement.focus();
      }
    );
  }
}
