<div class="chng-pwd-theme" *ngIf="isShowForm">
  <haven-loader [loaderEnabled]="isLoading"></haven-loader>
  <div class="headerContainer">
    <div class="row" style="padding: 15px;">
      <div class="col-lg-12">
        <img
          width="45px"
          style="float: left;"
          src="./assets/images/login-header-icon.svg"
        />
        <span class="chng-pwd-header-title">{{
          appConstant.LOGIN_FORM_HEADER_TITLE
        }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <span class="chng-pwd-header-text">{{
          appConstant.CHNG_PWD_HEADER
        }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 chng-pwd-header-email">
        <span>{{ userEmail }}</span>
      </div>
    </div>
    <form
      [formGroup]="passwordRegistrationForm"
      (ngSubmit)="onSubmit()"
      class="needs-validation"
    >
      <div class="row pwd-rules" *ngFor="let rule of pwdRules">
        <div class="col-lg-12 form-group chng-pwd-rules-text">
          <span
            class="fa text-icon"
            [ngClass]="{
              'fa-check-circle text-color-green':
                passwordRules[rule.ruleValidator],
              'fa-circle-thin text-color-blue': !passwordRules[
                rule.ruleValidator
              ]
            }"
          ></span>
          <div class="m-l-5">
            <span
              [ngClass]="{
                'text-color-green': passwordRules[rule.ruleValidator]
              }"
            >
              {{ rule.ruleText }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 formcontrol">
          <div class="col-12 form-group">
            <label>{{ appConstant.GHNG_PWD_NEW_PASSWORD }}</label>

            <input
              type="password"
              name="password"
              class="form-control"
              placeholder="New Password"
              formControlName="password"
              maxlength="15"
              autocomplete="off"
              tabindex="1"
              [ngClass]="{
                'is-invalid':
                  submitted &&
                  pwdRegForm.password.errors &&
                  pwdRegForm.password.errors.validatePassword,
                isValid: !(
                  pwdRegForm.password.errors &&
                  pwdRegForm.password.errors.validatePassword
                )
              }"
            />
            <span
              class="text-valid"
              *ngIf="
                !(
                  pwdRegForm.password.errors &&
                  pwdRegForm.password.errors.validatePassword
                )
              "
              >{{ appConstant.PWD_GOOD }}</span
            >
            <a
              href="javascript:void(0);"
              class="reset-link"
              *ngIf="
                pwdRegForm.password.value && pwdRegForm.password.value != ''
              "
              (click)="clearPassword()"
            >
              <span>&times;</span></a
            >
            <div
              *ngIf="submitted && pwdRegForm.password.errors"
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  pwdRegForm.password.errors &&
                  pwdRegForm.password.errors.validatePassword
                "
              >
                {{ appConstant.PWD_ST_REQ }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 formcontrol">
          <div class="col-12 form-group">
            <label>{{ appConstant.GHNG_PWD_CONFIRM_PASSWORD }}</label>
            <input
              type="password"
              name="confirmPassword"
              class="form-control"
              placeholder="Confirm Password"
              formControlName="confirmPassword"
              maxlength="15"
              autocomplete="off"
              tabindex="1"
              [ngClass]="{
                'is-invalid':
                  pwdRegForm.confirmPassword.errors &&
                  pwdRegForm.confirmPassword.errors.mustMatch,
                isValid: !pwdRegForm.confirmPassword.errors
              }"
            />
            <span class="text-valid" *ngIf="!pwdRegForm.confirmPassword.errors"
              >Match</span
            >
            <span
              class="text-in-valid"
              *ngIf="
                pwdRegForm.confirmPassword.errors &&
                pwdRegForm.confirmPassword.errors.mustMatch
              "
              >Not Match</span
            >
            <a
              href="javascript:void(0);"
              class="reset-link"
              *ngIf="pwdRegForm.confirmPassword.value != ''"
              (click)="clearConfirmPassword()"
            >
              <span>&times;</span></a
            >

            <div
              *ngIf="submitted && pwdRegForm.confirmPassword.errors"
              class="invalid-feedback"
            >
              <div *ngIf="pwdRegForm.confirmPassword.errors.required">
                {{ appConstant.CNFM_PWD_REQ }}
              </div>
              <div *ngIf="pwdRegForm.confirmPassword.errors.mustMatch">
                {{ appConstant.CNFM_PWD_NOT_MATCH }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 formcontrol">
          <div class="col-12 mt-1 form-group">
            <button
              type="submit"
              [disabled]="!passwordRegistrationForm.valid"
              class="btn btn-primary access-button mt-2"
              tabindex="3"
            >
              {{ appConstant.CHNG_PWD_BUTTON_TITLE }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
