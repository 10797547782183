export class Menu {
    DisplayName: string;
    Url: string;
    Order: number;
    Id: number;
    ParentId: number;
    Menus: Menu[];
    Disable: boolean;

    constructor(
      displayName: string,
      url: string,
      order: number,
      id: number,
      parentId: number = 0,
      disable: boolean
      ) {
      this.DisplayName = displayName;
      this.Url = url;
      this.Order = order;
      this.Id = id;
      this.ParentId = parentId;
      this.Disable = disable;
    }
  }

export class IMenu {
  displayName: string;
  url: string;
  order: number;
  id: number;
  parentId: number = 0;
  disable: boolean;
}
