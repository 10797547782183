import { ErrorResponseModel } from './responseModel';

export class UserRequestModel {
  userId: string;
  clientId: string;
  userRole: string;
  // TODO : search params TBD
}

export class UserResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: UserModel;
  // This class contains generic datatype property it may contain variety of data as well as null
  dataEnumerable: any[] = [];
  errors: ErrorResponseModel[] = [];
}

export class UserModel {
  userGuid: string = null;
  iManageUserId: string = '';
  loggedInName: string = '';
  mobile: string = '';
  primaryEmailAddress: string = '';
  secondaryEmailAddress: string = '';
  userRoleName?: string = 'User';
  isTestUser: boolean = false;
  isAllowLogin: boolean = true;
  contact: ConstantOptions = new ConstantOptions();
  action?: string = '';
  expirationInMinutes: number;
  jwtToken: string;
  expiration: string;
  clientGuid: string;
  selected?: boolean;
  roleName?: string = 'Admin';
  dataCenterInfo: any;
  disableBtn: boolean;
  isUserVerified: boolean;
  isUserLocked: boolean;
  forceUnlockUser: boolean;
}


export class ConstantOptions {
  isSms: boolean = false;
  isEmail: boolean = true;
}

export class UserCSVModel {
  primaryEmailAddress: string = '';
  mobileNumber: string = '';
  status: string = '';
  comment: string = '';
}

export class UserCSVResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: Array<DataCSV>;
  dataEnumerable: Array<[]>;
  errors: Array<ErrorCSV>;
}

export class DataCSV {
  status: string;
}

export class ErrorCSV {
  errorStatus: string;
  userMessage: string;
  devMessage: string;
}

export class UserInfo {
  userType: string;
  loggedInUserId: string;
}

export class UserGetReq {
  userType: string;
  requestedCount: number;
}
