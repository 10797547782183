export class ImangaeConnectionConfigurationModel {
  public ConnectionName: string;
  public DataCenter: string;
  public ImanageURL: string;
  public UserID: string;
  public Password: string;
  public FirstImanageLibrarie: string;
  public SecondImanageLibrarie: string;
  public ImanageLibrarie: [];
}
