import { SuspendUpdateTypeEnum } from '../enum/suspendUpdateTypeEnum';

export class SuspendUpdateModel {
  order: number;
  suspendUpdatesType: string;
  fromWeekDay: string;
  fromDate: string;
  fromTime: string;
  toWeekDay: string;
  toDate: string;
  toTime: string;
  isValidSuspendUpdate?: boolean;
  constructor() {
    this.suspendUpdatesType = SuspendUpdateTypeEnum.DAILY;
    this.fromWeekDay = SuspendUpdateTypeEnum.DAILY;
    this.isValidSuspendUpdate = false;
  }
}
