export class AuthenticateUserRequestModel {
  emailAddress: string;
  password: string;
}
export class ChangePasswordModel {
  userEmailId: string;
  password: string;
  encryptedUserId: string;
}
export class ForgotPasswordRequestModel {
  emailAddress: string;
}
export class UserLoginModel {
  email: string;
  password: string;
  constructor() {
    this.email = '';
    this.password = '';
  }
}
