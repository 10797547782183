<div class="login-theme">
  <haven-loader [loaderEnabled]="isLoading"></haven-loader>
  <div class="headerContainer">
    <div class="row" style="padding: 15px;">
      <div class="col-lg-12">
        <div class="logo-div">
        <img
          height="45px "
          src="./assets/images/HAVENFullColor.svg"
        /></div>
        <span class="login-header-title">{{
          appConstant.LOGIN_FORM_HEADER_TITLE
        }}</span>
      </div>
    </div>
    <form
      #loginForm="ngForm"
      name="form"
      (ngSubmit)="
        loginForm.form.valid && loginModel.password != '' && onSubmit()
      "
      class="needs-validation"
      novalidate
    >
      <div class="row">
        <div class="col-lg-12 formcontrol">
          <div
            class="col-12 form-group"
            [ngClass]="{
              'has-danger': email.invalid && (email.dirty || email.touched),
              'has-success': email.valid && (email.dirty || email.touched)
            }"
          >
            <label for="email">{{
              appConstant.LOGIN_FORM_EMAIL_ADDRESS_TITLE
            }}</label>

            <input
              type="email"
              name="email"
              class="form-control"
              placeholder="someone@example.com"
              [(ngModel)]="loginModel.email"
              #email="ngModel"
              required
              haven-emailValidator
              maxlength="256"
              autocomplete="off"
              tabindex="1"
            />
            <a
              href="javascript:void(0);"
              class="reset-link"
              *ngIf="loginModel.email != ''"
              (click)="loginModel.email = ''"
              tabindex="4"
            >
              <span>&times;</span></a
            >
            <div
              *ngIf="email.errors && (email.dirty || email.touched)"
              class="form-control-feedback text-danger"
            >
              <div *ngIf="email.errors.required">
                {{ appConstant.REQUIRED_VALIDATION_MESSAGE }}
              </div>
              <div
                *ngIf="email.errors.emailValidator && !email.errors.required"
              >
                {{ appConstant.INVALID_EMAIL_VALIDATION_MESSAGE }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 mt-2 formcontrol">
          <div
            class="col-12 form-group"
            [ngClass]="{
              'has-danger':
                password.invalid && (password.dirty || password.touched),
              'has-success':
                password.valid && (password.dirty || password.touched)
            }"
          >
            <label for="password">{{
              appConstant.LOGIN_FORM_PASSWORD_TITLE
            }}</label>
            <a
              href="javascript:void(0);"
              class="resend float-right"
              (click)="forgotPassword()"
              tabindex="5"
              *ngIf="email.valid && (email.dirty || email.touched)"
              >{{ appConstant.FORGOT_PWD_LABEL }}</a
            >
            <input
              type="password"
              name="password"
              class="form-control"
              placeholder="Password"
              #password="ngModel"
              required
              [(ngModel)]="loginModel.password"
              tabindex="2"
              autocomplete="off"
            />
            <a
              href="javascript:void(0);"
              class="reset-link"
              *ngIf="loginModel.password != ''"
              (click)="loginModel.password = ''"
              tabindex="6"              
            >
              <span>&times;</span></a
            >
            <div
              *ngIf="password.errors && (password.dirty || password.touched)"
              class="form-control-feedback text-danger"
            >
              <div *ngIf="password.errors.required">
                {{ appConstant.REQUIRED_VALIDATION_MESSAGE }}
              </div>
            </div>
          </div>
          <div class="col-12 mt-2">
            <button
              type="submit"
              class="btn btn-primary access-button mt-2"
              tabindex="3"
              [disabled]="!loginForm.form.valid"
            >
              {{ appConstant.SIGN_IN_BUTTON_TITLE }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
