<haven-header *ngIf="isHeaderVisible"></haven-header>
<haven-menu *ngIf="isHeaderVisible"></haven-menu>
<haven-timer (TimerExpired)="idleTimeExpired()" *ngIf="isHeaderVisible" class="display-none"></haven-timer>

<div>
  <haven-confirmation-dialog></haven-confirmation-dialog>
</div>
<div class="d-flex" id="wrapper" havenEvent>
  <div class="container-fluid p-0">
    <router-outlet></router-outlet>
  </div>
</div>
