import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Observable } from 'rxjs';

import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haven-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit, OnDestroy {
  @Input()
  Message: string;
  @Input() Title;
  @Input()
  OkBtnText;
  @Input()
  DeclineBtnText;
  @Input() SubMessage = '';
  private declineEvent = new EventEmitter<void>();
  private onDestroy = new EventEmitter();
  get decline(): Observable<any> {
      return this.declineEvent;
  }
  private acceptEvent = new EventEmitter<void>();
  get accept(): Observable<void> {
      return this.acceptEvent;
  }
  get confirm(): Observable<boolean> {
      return merge(
          this.accept.pipe(map(() => true)),
          this.decline.pipe(map(() => false))
      ).pipe(
          takeUntil(this.onDestroy)
      );
  }
  constructor(private bsModalRef: BsModalRef, private bsModelService: BsModalService) {
  }
  ok() {
      this.acceptEvent.emit();
      this.bsModalRef.hide();
  }
  then(accept: () => void, decline?: () => void) {
      this.accept.subscribe(accept);
      if (decline) {
          this.decline.subscribe(decline);
      }
  }
  onDecline() {
      this.declineEvent.emit();
      this.bsModalRef.hide();
  }
  close() {
    this.declineEvent.emit();
    this.bsModalRef.hide();
  }
  ngOnDestroy() {
    this.bsModalRef.hide();
    this.onDestroy.emit();
    this.declineEvent.complete();
    this.acceptEvent.complete();
  }
  ngOnInit() {

  }
}
