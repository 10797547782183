import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { NotificationService } from '.';
import appConstant from '../../assets/app-text.json';
import { configJSON } from '../appURLConfig';
import { ResponseMessageType } from '../common/enum/responseMessageType';
import { ResponseModel } from '../common/models/responseModel';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  appConstant;
  public subject = new Subject<any>();
  public refreshTokenSubject = new Subject<any>();
  public isTimedOut = false;
  public idleTimeSubject = new Subject<any>();
  constructor(
    private authService: AuthService,
    private router: Router,
    private http: HttpClient,
    private notificationService: NotificationService
  ) {
    this.appConstant = appConstant;
  }
  timerRefresh() {
    this.idleTimeSubject.next({ isTimerToBeRefreshed: true });
  }
  idleTimedOut() {
    this.isTimedOut = true;
  }
  getTimerRefreshMessage(): Observable<any> {
    return this.idleTimeSubject.asObservable();
  }
  tokenRefreshed() {
    this.refreshTokenSubject.next({ isTokenRefreshed: true });
  }
  getRefreshTokenMessage(): Observable<any> {
    return this.refreshTokenSubject.asObservable();
  }
  checkTokenValidity() {
    if (
      !this.authService.getClientId() ||
      !this.authService.getUserId()
    ) {
      if (
        this.router.url.indexOf('login') === -1 &&
        this.router.url.indexOf('setpassword') === -1
      ) {
        this.authService.logout();
      }
    } else {
      this.timerRefresh();
    }
  }
  getRefreshedToken(req: { emailAddress: string }): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(configJSON.refreshToken, req,{withCredentials: true});
  }
  refreshToken(email: string) {
    const req = {
      emailAddress: email
    };
    this.getRefreshedToken(req).subscribe(
      (result) => {
        if (!!result) {
          if (result.message === ResponseMessageType.SUCCESS) {
            this.authService.updateRefreshToken(result.data);
          }
        }
      }
    );
  }
}
