<!-- Basic Modal -->
<div style="display: block !important;" class="modal"
  tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          Uploaded User Validation
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="information-text" [innerHTML]="popupText.phoneNumberPopupInformationText"></p>
          <p>Total Records: {{updateddData.length}}
            Valid Records: {{updateddData.length - uploadedRecord.length}}
            Error Records: {{uploadedRecord.length}}</p>
        <div *ngIf="uploadedRecord.length">
            Records in error:  
            <div class="scroll-400">
            <table class="table">
            <thead>
              <tr >
                <th *ngFor="let headertitle of header" scope="col" class="text-overflow">{{popupText[headertitle]}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of uploadedRecord; let i = index">
                <td scope="row" *ngFor="let coloum of header" class="text-overflow">
                        {{row[coloum]}}
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          </div>
    </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-yes" (click)="onSendInEmail()">
            Send in Email
        </button>
        <button type="button" class="btn btn-no" (click)="close()">
          Close
        </button>
        
      </div>
    </div>
  </div>
</div>