import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'haven-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent implements OnInit {
  @Input()
  loaderEnabled: boolean = false;
  constructor() {}

  ngOnInit() {}
}
