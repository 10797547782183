import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import  config from '../../assets/config.json';
import { configJSON } from '../appURLConfig';
import { UserCSVModel, UserCSVResponseModel, UserModel } from '../common/models/userModel';


@Injectable({
  providedIn: 'root',
})
export class UsersService {
  adminUserList: UserModel[];
  constructor(private http: HttpClient) {}


  getUserList(url: any, req: any): Observable<any> {
    return this.http.post(url + config.getUserData, req,{withCredentials: true});
  }

  getAdminList(req: any): Observable<any> {
    return this.http.post(configJSON.getAdminUserListCatalog, req,{withCredentials: true});
  }

  addEditAdmin( data: any ): Observable<any> {
    return this.http.post(configJSON.addUpdateAdminUserCatalog , data,{withCredentials: true});
  }

  validateUser(userId: string) {
    const req = { encryptedUserId: userId };
    return this.http.post(configJSON.getUserVerification, req,{withCredentials: true});
  }

  saveChangesUserdata(url: string, data: any ): Observable<any> {
    return this.http.post(url + config.saveUserData, data,{withCredentials: true});
  }

  sendEmail(data: UserCSVModel[]): Observable<UserCSVResponseModel> {
    return this.http.post<UserCSVResponseModel>(configJSON.sendEmail , data,{withCredentials: true});
  }
}
