import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import Utils from 'src/app/common/utils/app.utils';
import { userType } from '../../common/enum/userType';
import { Menu } from '../../common/models/menu';
import { AuthService, MenuService } from '../../services';

@Component({
  selector: 'haven-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  showChildnav = false;
  currentId;
  userType = userType;
  accessMenus: Menu[] = [];
  loggedInUser: string;
  loggedInUserRole: string;
  constructor(
    public menuService: MenuService,
    public router: Router,
    private authService: AuthService
  ) {
  }

  connectionSubHeader = [{
      displayName: 'Connections',
      url: '/connectionsconfiguration/connections',
      order: 1},
      {
      displayName: 'Administrators',
      url: '/connectionsconfiguration/administrators',
      order: 2}];

  ngOnInit() {
    this.accessMenus = this.menuService.getAllMenus();
    this.loggedInUser = this.authService.getUserName();
    this.loggedInUserRole = this.authService.getUserRole();
  }
  getuserRole(type: string) {
    return Utils.getUserRole(type);

  }


}
