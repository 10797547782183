import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import appConstant from '../../../assets/app-text.json';
import popupConfig from '../../../assets/updatedUserPopup.json';
import {NotificationService, UsersService } from '../../services';
@Component({
  selector: 'haven-updated-grid',
  templateUrl: './updated-grid.component.html',
  styleUrls: ['./updated-grid.component.scss']
})
export class UpdatedGridComponent implements OnInit, OnDestroy {

  constructor( private bsModalRef: BsModalRef, private bsModelService: BsModalService, private userService: UsersService,
               private notificationService: NotificationService ) { }
  header = [];
  @Input() updateddData;
  uploadedRecord = [];
  popupText = popupConfig;
  ngOnInit() {
    this.uploadedRecord = this.updateddData.filter(elmt => elmt.status === 'Error');
    if (this.uploadedRecord && this.uploadedRecord.length > 0) {
      this.header = Object.keys(this.uploadedRecord[0]).filter((key) => key !== 'status');
    }
  }
  close() {
    this.bsModalRef.hide();
  }

  onSendInEmail() {
    this.userService.sendEmail(this.updateddData).subscribe((response) => {
      if (response && response.data && response.message === 'Success') {
        this.notificationService.success(appConstant.EMAIL_REQUEST_SUCCESS);
        this.bsModalRef.hide();
      } else {
          this.bsModalRef.hide();
        }
      }, (error) =>  {
      this.bsModalRef.hide();
    });
  }

  ngOnDestroy() {
    this.bsModalRef.hide();
  }
}
