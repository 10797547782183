<!-- Basic Modal -->
<div style="display: block !important;" class="modal"
  tabindex="-1" role="dialog">
  <div style="min-width:434px" class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          {{Title}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- -->
        <div>
          <div class="messageTitle" style="display:inline" >{{Message}}</div>
          <div class="messageTitle" *ngIf="SubMessage">{{SubMessage}}</div>
        </div>
        <div class="messageText" ></div>
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-no" (click)="ok()">
          {{OkBtnText}}
        </button>
        <button type="button" class="btn btn-yes" (click)="onDecline()">
          {{DeclineBtnText}}
      </button>
      </div>
    </div>
  </div>
</div>