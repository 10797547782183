<div class="custom-header-label-top">
  <div class="year">{{ yearLabel }}</div>
  <div class="date">{{ dateLabel }}</div>
</div>
<div class="custom-header">
 
  <button mat-icon-button (click)="previousClicked('month')">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <span class="custom-header-label">{{ periodLabel }}</span>
  <button mat-icon-button (click)="nextClicked('month')">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
 
</div>
