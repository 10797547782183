import { Directive, ElementRef, HostListener  } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[havenInputTextTrim]'
})
export class InputTextTrimDirective {

  constructor(private el: ElementRef, private control: NgControl) {
  }
  @HostListener('blur') onBlur() {
    const trimValue = this.el.nativeElement.value.trim();
    this.control.control.setValue(trimValue);
  }

}
