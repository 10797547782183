import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../common/models';
import { ConfirmationDialogService } from './confirmation-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authServiceSubject = new Subject<any>();

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}
  getAdminUserData(): UserModel {
    const clientUserData = this.getCookie('admin_user_data');
    return clientUserData === ''
      ? new UserModel()
      : JSON.parse(this.getCookie('admin_user_data'));
  }
  getUserToken() {
    return this.getCookie('user_token');
  }
  getUserRole() {
    return this.getAdminUserData().userRoleName;
  }
  getUserName() {
    return this.getAdminUserData().loggedInName;
  }
  getUserId() {
    return this.getAdminUserData().userGuid;
  }
  getClientId() {
    return this.getAdminUserData().clientGuid;
  }
  getLoggedInUserContact() {
    return this.getAdminUserData().contact;
  }
  isLoggedInUserATestUser() {
    return this.getAdminUserData().isTestUser === true;
  }
  getMobile() {
    return this.getAdminUserData().mobile;
  }
  getIManageUserId() {
    return this.getAdminUserData().iManageUserId === null
      ? ''
      : this.getAdminUserData().iManageUserId;
  }
  getTokenLifeTime() {
    return this.getAdminUserData().expirationInMinutes;
  }
  getExpirationTime() {
    return this.getAdminUserData().expiration;
  }
  // Observable can be set with different  data type user can pass any object in it
  setSubject(data: any) {
    this.authServiceSubject.next(data);
  }
  clearSubject() {
    return this.authServiceSubject.next();
  }
  // Observable can be get with different  data type user can pass any object in it
  getSubject(): Observable<any> {
    return this.authServiceSubject.asObservable();
  }
  getUserEmail() {
    return this.getAdminUserData().primaryEmailAddress;
  }
  getNewValidatedUserEmail() {
    return JSON.parse(this.getCookie('changePasswordData')).primaryEmailAddress;
  }
  setAuthenticatedCookies(userData: UserModel) {
    const expirationInMinutes = userData.expirationInMinutes;
    this.setCookie(
      'admin_user_data',
      JSON.stringify(userData),
      expirationInMinutes
    );
  }
  setChangePasswordCookies(userData: UserModel) {
    const expirationInMinutes = userData.expirationInMinutes;
    this.setCookie(
      'changePasswordData',
      JSON.stringify(userData),
      expirationInMinutes
    );
  }
  getchangePasswordData() {
    return JSON.parse(this.getCookie('changePasswordData'));
  }
  updateRefreshToken(userData: UserModel) {
    const expirationInMinutes = userData.expirationInMinutes;
    const existingUserData = this.getAdminUserData();
    existingUserData.jwtToken = userData.jwtToken;
    existingUserData.expiration = userData.expiration;
    existingUserData.expirationInMinutes = userData.expirationInMinutes;
    this.deleteCookies();
    this.cookieService.deleteAll();
    this.cookieService.deleteAll('/');
    this.setCookie(
      'admin_user_data',
      JSON.stringify(existingUserData),
      expirationInMinutes
    );
    this.updateCookies(expirationInMinutes);
  }

  // A generic method to set the cookie of different types of values
  setCookie(key: string, value: any, expires: number = 0) {
    const date = new Date(Date.now() + expires * 60 * 1000);
    if (environment.envName === 'local') {
        document.cookie = `${key}=${value};expires=${date.toUTCString()}`;
    } else {
        document.cookie = `${key}=${value};expires=${date.toUTCString()};Secure;SameSite=None;`;
    }
  }
  getCookie(cname: string) {
    const name = cname + '=';
    let cookieValue = '';
    const ca: string[] = document.cookie.split(';');
    if (ca.length > 0) {
      ca.forEach((cookie) => {
        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
          cookieValue = cookie.substring(name.length, cookie.length);
        }
      });
    }
    return cookieValue;
  }
  clearCookies() {
    this.confirmationDialogService.clearMessage();
    this.deleteCookies();
    this.cookieService.deleteAll();
    this.cookieService.deleteAll('/');
  }
  logout() {
    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl: this.router.url,
      },
    });
  }
  updateCookies(expirationInMinutes: number) {
    const date = new Date(Date.now() + expirationInMinutes * 60 * 1000);
    const res = document.cookie;
    if (res !== '') {
      const multiple: string[] = res.split(';');
      if (multiple.length > 0) {
        multiple.forEach((cookie) => {
          const key = cookie.split('=');
          if (environment.envName === 'local') {
              document.cookie = `${key[0]}=${key[1]}; expires=${date.toUTCString()}`;
          } else {
              document.cookie = `${key[0]}=${key[1]}; expires=${date.toUTCString()};Secure;SameSite=None;`;
          }

        });
      }
    }
  }
  deleteCookies() {
    const res = document.cookie;
    if (res !== '') {
      const multiple: string[] = res.split(';');
      if (multiple.length > 0) {
        multiple.forEach((cookie) => {
          const key = cookie.split('=');
          document.cookie =
            key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC;';
        });
      }
    }
  }
}
