import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshPageService {
  constructor() {}
  public refreshPageServiceSubject = new Subject<any>();
  // Observable can be set with different  data type user can pass any object in it
  setSubject(data: any) {
    this.refreshPageServiceSubject.next(data);
  }

  clearSubject() {
    return this.refreshPageServiceSubject.next();
  }
  // Observable can be get with different  data type user can pass any object in it
  getSubject(): Observable<any> {
    return this.refreshPageServiceSubject.asObservable();
  }
}
