import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  config = {
    closeButton: true,
    timeOut: 3000,
    extendedTimeOut: 1000,
    easing: 'ease-out',
    easeTime: 1000,
    disableTimeOut: false,
    progressBar: true,
    tapToDismiss: true,
    enableHtml: true,
  };
  constructor(private messenger: ToastrService) {}
  public notificationServiceSubject = new Subject<any>();
  // Observable can be set with different  data type user can pass any object in it
  setSubject(data: any) {
    this.notificationServiceSubject.next(data);
  }
  clearSubject() {
    return this.notificationServiceSubject.next();
  }
  // Observable can be get with different  data type user can pass any object in it
  getSubject(): Observable<any> {
    return this.notificationServiceSubject.asObservable();
  }
  success(message?: string, title?: string) {
    this.messenger.success(message, title, this.config);
  }
  info(message?: string, title?: string) {
    this.messenger.info(message, title, this.config);
  }
  warn(message?: string, title?: string) {
    this.messenger.warning(message, title, this.config);
  }
  error(message?: any, title?: string) {
    const errorMessage = this.getMessage(message);
    this.messenger.error(errorMessage, title, this.config);
  }

  getMessage(error: any ) {
    if (typeof(error) === 'string') {
      return error;
    } else {
      const allMessage =  error.errors.map(x => x.userMessage).toString();
      return (error.dataCenter ? error.dataCenter + ': ' : '') + allMessage;
    }
  }
}
