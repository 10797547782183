import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], filter: any): any {
    if (!items || !filter) {
        return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    const result = items.filter(item => {
      return item.loginName.toString().toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    });

    if (result.length === 0) {
      return [-1];
    }
    return result;
}

}
