import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'haven-custom-date-picker-header',
  templateUrl: './custom-date-picker-header.component.html',
  styleUrls: ['./custom-date-picker-header.component.scss'],
})
export class CustomDatePickerHeaderComponent<D> implements OnDestroy {
  private destroyed = new Subject<void>();
  date: any;
  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
      cdr.markForCheck();
    });
    this.date = new Date();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get periodLabel() {
    return this.dateAdapter.format(
      this.calendar.activeDate,
      this.dateFormats.display.monthYearA11yLabel
    );
  }
  get yearLabel() {
    return this.dateAdapter.getYear(this.calendar.activeDate);
  }
  get dateLabel() {
    return (
      this.dateAdapter.getDayOfWeekNames('short')[
        this.dateAdapter.getDayOfWeek(this.calendar.activeDate)
      ] +
      ', ' +
      this.dateAdapter.getMonthNames('short')[
        this.dateAdapter.getMonth(this.calendar.activeDate)
      ] +
      ' ' +
      this.dateAdapter.getDate(this.calendar.activeDate)
    );
  }
  previousClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }
}
