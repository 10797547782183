import { ErrorResponseModel } from './responseModel';

export class IHomeImangeResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: ImanageConnectionData[] = [];
  // This class contains generic datatype property it may contain variety of data as well as null
  dataEnumerable: any[] = [];
  errors: ErrorResponseModel[] = [];
}

export class ImanageConnectionData {
  connectionGuid: string;
  connectionName: string;
  iManageServerURL: string;
  connectionAccessType: string;
  dataCenterGuid: string;
  iManageUserId: string;
  dataCenterName: string;
  isEnabled: boolean;
  accessPeriodStart: string;
  accessPeriodEnd: string;
  lastSynchronizationDate: string;
  havenSynchronizationStatus?: string;
  userSynchronizationStatus?: string;
  userLastSynchronizationDate?: string;
  havenLastSynchronizationDate?: string;
  isConnectionStateActive: boolean;
  visible: boolean;
  iManageLibraries: IManageLibraries[];
  synchronizationStatus: string;
}

export class IManageLibraries {
  name: string;
  number: number;
  libraryGuid: string;
  isSelected: boolean;
}
export class ConnectionConfigModal {
  headerText: string;
  bodyText: string;
  modalType: string;
}
export class AccessCodeRequestModel {
  emailAddress: string;
}

