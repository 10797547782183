<nav class="navbar navbar-expand-lg navbar-light bg-white">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item open-sub-menu" *ngFor="let menu of accessMenus">
        <a
          class="nav-link"
          *ngIf="menu.ParentId == 0"
          routerLink="{{ menu.Url }}"
          routerLinkActive="active"
          [ngClass]="{
            'menu-disable': menu.Disable
          }"
          [tabindex]="menu.Disable ? -1 : 0"
          ><span class="underline-menu-item">{{ menu.DisplayName }}</span>
        </a>
        <div class="sub-menu">
          <div class="sub-menu-inner" *ngFor="let childMenu of menu.Menus">
            <a
              class="nav-link"
              routerLink="{{ childMenu.Url }}"
              routerLinkActive="active"
              [ngClass]="{
                'menu-disable': childMenu.Disable
              }"
              [tabindex]="childMenu.Disable ? -1 : 0"
              ><span class="underline-menu-item">{{
                childMenu.DisplayName
              }}</span></a
            >
          </div>
        </div>
      </li>
    </ul>
    <div class="form-inline my-2 my-lg-0 setting-box">
      <span class="red-alert-font margin-right10"
        >{{getuserRole(loggedInUserRole) == userType.SUPERADMIN ? 'Super Admin' : getuserRole(loggedInUserRole)}} : {{ loggedInUser }} is currently logged in</span
      >
      <a class="setting-icon" 
      routerLinkActive="active"
      title="Connection Configuration"
      routerLink="/connectionsconfiguration">
        <span>
          <svg
            preserveAspectRatio="none"
            version="1.1"
            id="s-Image_36-Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="25px"
            height="25px"
            viewBox="0 0 120 120"
            enable-background="new 0 0 120 120"
            xml:space="preserve"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M110.97,53.096h8.869v13.81h-8.869
              c-0.602,4.4-1.721,8.634-3.38,12.599l7.683,4.438l-6.905,11.957l-7.816-4.514c-2.685,3.39-5.799,6.406-9.256,9.013l4.604,7.97
              l-11.957,6.905l-4.698-8.133c-3.906,1.524-8.032,2.585-12.338,3.098v9.602h-13.81v-9.862c-4.199-0.646-8.244-1.753-12.033-3.367
              l-5.003,8.663l-11.958-6.905l5.125-8.873c-3.21-2.571-6.073-5.538-8.573-8.806l-9.025,5.21L4.724,83.941l9.107-5.26
              c-1.479-3.73-2.513-7.678-3.075-11.776H0.161v-13.81h10.595c0.562-4.104,1.596-8.049,3.075-11.777L4.724,36.06l6.904-11.958
              l9.025,5.211c2.5-3.269,5.363-6.235,8.573-8.809l-5.125-8.873l11.958-6.905l5.003,8.665c3.79-1.611,7.834-2.72,12.033-3.366V0.161
              h13.81v9.6c4.306,0.513,8.432,1.575,12.338,3.099l4.698-8.134l11.957,6.905l-4.604,7.972c3.457,2.606,6.571,5.623,9.256,9.013
              l7.816-4.514l6.905,11.958l-7.683,4.437C109.249,44.461,110.368,48.694,110.97,53.096z M59.959,30.04
              c-16.523,0-29.919,13.396-29.919,29.919c0,16.524,13.396,29.921,29.919,29.921c16.524,0,29.921-13.396,29.921-29.921
              C89.88,43.436,76.483,30.04,59.959,30.04z M60,71.508c-6.356,0-11.508-5.151-11.508-11.508S53.644,48.492,60,48.492
              c6.357,0,11.508,5.151,11.508,11.508S66.356,71.508,60,71.508z"
            ></path>
          </svg>
        </span>
        <!-- <div class="main-wrapper setting-sub-menu">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/connectionsconfiguration/connections"
                routerLinkActive="active"
              >
                <span class="underline-menu-item">Connections</span></a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/connectionsconfiguration/administrators"
                routerLinkActive="active"
              >
                <span class="underline-menu-item">Administrators</span></a
              >
            </li>
          </ul>
        </div> -->
      </a>
    </div>
  </div>
</nav>
<!-- <div class="main-wrapper child-nav">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto" >
          <li class="nav-item" *ngFor="let childMenu of accessMenus">
            <a class="nav-link child-menu" routerLink="{{childMenu.Url}}" *ngIf="childMenu.ParentId == currentId && currentId!=0"  routerLinkActive="active"><span class="underline-menu-item">{{childMenu.DisplayName}}</span></a>
          </li>
        </ul>
      </div>
    </nav>
</div> -->
