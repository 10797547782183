export class ConnectionDetailModel {
  connectionName: string;
  dataCenterName: string;
  connectionGuid: string;
  url: string;
  dataCenterGuid: string;
  isEnabled: boolean;
}


export class DataCenterModel {
  dataCenterName: string;
  url: string;
  dataCenterGuid: string;
  isEnabled: boolean;
  iManageServerUrl: string;

}

export class DataCenterResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: DataCenterModel[];
  errors: any;
}
