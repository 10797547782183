import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultiSelectModel } from 'src/app/common/models/multi-select.model';

@Component({
  selector: 'haven-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectDropdownComponent),
      multi: true,
    },
  ],
})
export class MultiselectDropdownComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @HostBinding('attr.id')
  externalId = '';
  @Input() dataList: MultiSelectModel[] = [];
  @Input() formClass: string;
  @Output() selectValueEvent = new EventEmitter();
  isShowlibraryList = false;
  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  get id() {
    return this._ID;
  }
  private _ID = '';
  @Input() inputvalue = false;
  // these are the exnsions of a normal select  thus change event and other event doesnt have any specific datatype
  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.inputvalue;
  }

  set value(val: any) {
    this.inputvalue = val;
    this.onChange(val);
    this.onTouched();
  }
  constructor(private eRef: ElementRef) {}

  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   console.log('here');
  //   this.onShowListPopup(this.isShowlibraryList);
  // }

  // @HostListener('keydown', ['$event']) onKeyDown(e) {
  //   if (e.keyCode === 9) {
  //     const focusable = document.querySelector('#modal').querySelectorAll('input,button,select,checkbox');
  //     if (focusable.length) {
  //       const first = focusable[0];
  //       const last = focusable[focusable.length - 1];
  //       const shift = e.shiftKey;
  //       if (shift) {
  //             if (e.target === first) { // shift-tab pressed on first input in dialog
  //                 this.last.nativeElement.focus();
  //                 e.preventDefault();
  //             }
  //         } else {
  //             if (e.target === last) { // tab pressed on last input in dialog
  //                 this.first.nativeElement.focus();
  //                 e.preventDefault();
  //             }
  //         }
  //     }
  //   }
  // }

  writeValue(value: any): void {
    if (value) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.dataList && changes.dataList.currentValue) {
      this.getSelectedValue(this.dataList);
    }
  }
  ngOnInit() {
    this.getSelectedValue(this.dataList);
  }

  onShowListPopup(isShow: boolean) {
    this.isShowlibraryList = !isShow;
  }

  // Selected Value with array
  onSelectListValue(dataitem: MultiSelectModel) {
    this.getSelectedValue(this.dataList);
  }

  // Create comma seprator Value
  getSelectedValue(selectedListArray: MultiSelectModel[]) {
    this.value = selectedListArray
      .filter((item) => item.isSelected)
      .map((item) => item.text)
      .join(', ');
    //  this.sendselectedValue(this.dataList);
  }

  sendselectedValue(selectValue: any) {
    this.selectValueEvent.emit(selectValue);
  }

  // @HostListener('document:click', ['$event'])
  // handleOutsideClick(event: any) {
  //   if (this.eRef.nativeElement.contains(event.target) === false) {
  //     this.isShowlibraryList = false;
  //   }
  // }

}
