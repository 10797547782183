import { Directive } from '@angular/core';
import {
  FormControl,
  NG_VALIDATORS,
  Validator,
  ValidatorFn
} from '@angular/forms';

@Directive({
  selector: '[haven-emailValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true
    }
  ]
})
export class EmailValidatorDirective implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = this.emailValidator();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
  emailValidator(): ValidatorFn {
    return (c: FormControl) => {
      const isValid = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(
        c.value
      );
      if (isValid) {
        return null;
      } else {
        return {
          emailValidator: {
            valid: false
          }
        };
      }
    };
  }
}
