import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

@Component({
  selector: 'haven-custom-time-picker',
  templateUrl: './custom-time-picker.component.html',
  styleUrls: ['./custom-time-picker.component.scss'],
})
export class CustomTimePickerComponent implements OnInit {
  @Input() setTime = '';
  @Output() timeChanged = new EventEmitter();
  customTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#174579',
    },
    dial: {
      dialBackgroundColor: '#174579',
    },
    clockFace: {
      clockFaceBackgroundColor: '#f0f0f0',
      clockHandColor: '#174579',
      clockFaceTimeInactiveColor: '#6c6c6c',
    },
  };
  constructor() {}

  ngOnInit() {}
  onTimeChanged(event: string) {
    this.timeChanged.emit(event);
  }
}
