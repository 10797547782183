import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
// jQuery is an object having the whole jquery.min.js in one object thus it does not have any specific data type
declare var jQuery: any;
@Directive({
  selector: '[havenDateTimePicker]',
})
export class DateTimePickerDirective implements OnInit {
  @Input() value = '';
  @Input() minimumDate: boolean = false;
  @Input() onlyTimePicker: boolean = false;
  @Output() dateChange = new EventEmitter();

  constructor(public el: ElementRef) {}
  ngOnInit() {
    if (
      this.value === undefined ||
      this.value === null ||
      this.value === 'null' ||
      this.value === 'null null'
    ) {
      this.value = '';
    }
    /*showOn: 'button',
          buttonImage: '../assets/images/calender-time.svg',
          buttonImageOnly: true,*/
    if (this.onlyTimePicker) {
      jQuery(this.el.nativeElement)
        .timepicker({
          format: 'LT',
          minDate:
            this.minimumDate === false
              ? ''
              : new Date(Date.now() + 24 * 60 * 60 * 1000),
          onSelect: (value) => {
            this.value = value;
            this.dateChange.next(value);
          },
        })
        .timepicker('setTime', this.value.trim() === '' ? null : this.value);
    } else {
      jQuery(this.el.nativeElement)
        .datetimepicker({
          oneLine: true,
          format: 'LT',
          minDate:
            this.minimumDate === false
              ? ''
              : new Date(Date.now() + 24 * 60 * 60 * 1000),
          onSelect: (value) => {
            this.value = value;
            this.dateChange.next(value);
          },
        })
        .datetimepicker(
          'setDate',
          this.value.trim() === '' ? null : this.value
        );
    }
  }
}
