<div class="main-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm">
        <div class="logo">
          <img class="logo-icon" src="../assets/images/HAVENLogoWhite.svg" />
        </div>
      </div>
      <div class="col-sm">
        <div class="sign-out">
          <div class="inline-element margin-right10">
            <div class="header-text_width text-overflow margin-right5">
              <span [title]="loggedInUser">{{ loggedInUser }}</span>
            </div>
            |
            <div class="header-text_width margin-left5">
              <span class="clickable" (click)="onLogout()">Sign Out</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
