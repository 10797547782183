import { Injectable } from '@angular/core';
import  menuConfig from '../../assets/menu.json';

import { IMenu, Menu } from '../common/models/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

 constructor() { }
  getAllMenus(): Menu[] {
    const items: Menu[] = [];
    const menus = menuConfig.Menus;
    menus.forEach((menu => {
      items.push(this.populateMenu(menu));
    }));
    return this.MenuRestructorization(items);
//  return items;
  }
  MenuRestructorization(items: Menu[]) {
    let menus: Menu[] = [];
    menus = items.filter(menu => menu.ParentId === 0);
    menus.forEach(menuItem => {
      menuItem.Menus = items.filter(menu => menu.ParentId === menuItem.Id);
    });
    return menus;
  }

  private populateMenu(menu: IMenu): Menu {
    return new Menu(
      menu.displayName,
      menu.url,
      menu.order,
      menu.id,
      menu.parentId,
      menu.disable,

    );
  }

}
