import { environment } from 'src/environments/environment';
import config from '../assets/config.json';

export let baseURL = environment.apiBaseURL;

export let configJSON = {
  validateAdminUser: baseURL + config.validateAdminUserCatalog,
  refreshToken: baseURL + config.refreshTokenCatalog,
  getUserVerification: baseURL + config.getUserVerificationCatalog,
  updatePassword: baseURL + config.updatePasswordCatalog,
  getDataCenter: baseURL + config.dataCenterCatalog,
  forgotPassword: baseURL + config.forgotPasswordCatalog,
  generateAdminCode: baseURL + config.generateAdminCodeCatalog,
  getAdminUserListCatalog: baseURL + config.getAdminUserListCatalog,
  addUpdateAdminUserCatalog: baseURL + config.addUpdateAdminUserCatalog,
  sendEmail: baseURL + config.csvUpdateSendEmailCatalog,
  testConnection: baseURL + config.testConnectionCatalog,
  // following to be refactor later
  getConnectionData: baseURL + config.connectionData,
  getSchedulingData: baseURL + config.getSchedulingData,
  updateSchedulingData: baseURL + config.updateSchedulingData,
  getLogoutUser:baseURL + config.getLogoutUser
  // enableDisableConnection: baseURL + config.enableDisableConnection,
  // addUpdateConnection: baseURL + config.addUpdateConnection,
  // getUserData: baseURL + config.getUserData,
  // saveUserData: baseURL + config.saveUserData,
  // updateConnectionState: baseURL + config.updateConnectionState
};

export let isProduction = environment.production;
