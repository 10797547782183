import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TokenService } from 'src/app/services/token.service';
import appConstant from '../../../assets/app-text.json';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';

export interface CanComponentDeactivate {
  hasUnSavedChanges(): boolean;
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(
    private bsModal: BsModalService,
    private tokenService: TokenService
  ) {}
  bsModalRef: BsModalRef;

  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (component.hasUnSavedChanges() && !this.tokenService.isTimedOut) {

      const contents = this.bsModal.show(ConfirmationModalComponent, {
        initialState: {
          Title: appConstant.CONFIRM_TITLE,
          Message: appConstant.CONFIRM_MSG,
          OkBtnText: appConstant.CONFIRM_YES,
          DeclineBtnText: appConstant.CONFIRM_NO,
        },
      }).content;
      contents.then(() => {});
      return contents.confirm;
    } else {
      return true;
    }
  }
}
