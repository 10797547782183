import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { TokenService } from 'src/app/services/token.service';
const moment = require('moment');
@Component({
  selector: 'haven-timer',
  templateUrl: './app-timer.component.html',
  styleUrls: ['./app-timer.component.scss'],
})
export class AppTimerComponent implements OnInit, OnDestroy {
  private idleTimerSubscription: Subscription;
  private refreshTimerSubscription: Subscription;
  @Output() TimerExpired: EventEmitter<any> = new EventEmitter<any>();
  @Input() RefreshTokenSearchDate = moment().utc();
  @Input() SearchDate = moment().utc();
  @Input() RefreshTokenElapsTime = 3;
  @Input() ElapsTime = 3;
  // refreshTokensearchEndDate, searchEndDate contains the dynamic data which is manipulate by moment library
  // and it is of Moment types
  refreshTokensearchEndDate: any;
  searchEndDate: any;
  refreshTimerRemainingTime: number;
  refreshTimerMinutes: number;
  refreshTimerSeconds: number;
  refreshTimerEverySecond: Observable<number> = timer(0, 1000);
  idleTimerRemainingTime: number;
  idleTimerMinutes: number;
  idleTimerSeconds: number;
  idleTimerEverySecond: Observable<number> = timer(0, 1000);
  isTokenRefreshed = false;
  count = 0;
  constructor(
    private ref: ChangeDetectorRef,
    private authService: AuthService,
    private tokenService: TokenService
  ) {
    this.isTokenRefreshed = false;
    this.RefreshTokenElapsTime =
      this.authService.getTokenLifeTime();
    this.ElapsTime = this.authService.getTokenLifeTime() * 4;
    this.searchEndDate = this.SearchDate.add(this.ElapsTime, 'minutes');

    this.refreshTokensearchEndDate = this.RefreshTokenSearchDate.add(
      this.RefreshTokenElapsTime,
      'minutes'
    );
  }

  ngOnInit() {
    // Refresh Token Timer
    this.refreshTimerSubscription = this.refreshTimerEverySecond.subscribe(
      (seconds) => {
        this.tokenService.getRefreshTokenMessage().pipe(delay(1000)).subscribe((response) => {
          if (response.isTokenRefreshed) {
            this.RefreshTokenSearchDate = moment().utc();
            this.refreshTokensearchEndDate = this.RefreshTokenSearchDate.add(
              this.RefreshTokenElapsTime,
              'minutes'
            );
            this.count = 0;
            this.isTokenRefreshed = false;
          }
        });
        const currentTime = moment(new Date().toUTCString());
        const tokenExpirationTime = moment(
          new Date(
            `${this.authService.getExpirationTime()} +0000`
          ).toUTCString()
        );
        this.count = this.count + 1;
        this.refreshTimerRemainingTime = this.refreshTokensearchEndDate.diff(
          currentTime
        );
        this.refreshTimerRemainingTime = this.refreshTimerRemainingTime / 1000;
        const tokenLifeTime = this.authService.getTokenLifeTime();
        if (
          (this.refreshTimerRemainingTime / 60 <= tokenLifeTime * 0.2 ||
            tokenExpirationTime.diff(currentTime) / 60000 <=
              tokenLifeTime * 0.2) &&
          !this.isTokenRefreshed
        ) {
          this.tokenService.refreshToken(this.authService.getUserEmail());
          this.isTokenRefreshed = true;
          this.tokenService.tokenRefreshed();
        } else {
          this.refreshTimerMinutes = Math.floor(
            this.refreshTimerRemainingTime / 60
          );
          this.refreshTimerSeconds = Math.floor(
            this.refreshTimerRemainingTime - this.refreshTimerMinutes * 60
          );
        }
        this.ref.markForCheck();
      }
    );
    // Idle Time Time
    this.idleTimerSubscription = this.idleTimerEverySecond.subscribe(
      (seconds) => {
        this.tokenService.getTimerRefreshMessage().subscribe((response) => {
          if (response.isTimerToBeRefreshed) {
            this.SearchDate = moment().utc();
            this.searchEndDate = this.SearchDate.add(this.ElapsTime, 'minutes');
            this.count = 0;
          }
        });
        const currentTime = moment().utc();
        this.count = this.count + 1;
        this.idleTimerRemainingTime = this.searchEndDate.diff(currentTime);
        this.idleTimerRemainingTime = this.idleTimerRemainingTime / 1000;
        if (this.idleTimerRemainingTime / 60 <= 0) {
          this.tokenService.idleTimedOut();
          this.TimerExpired.emit();
        } else {
          this.idleTimerMinutes = Math.floor(this.idleTimerRemainingTime / 60);
          this.idleTimerSeconds = Math.floor(
            this.idleTimerRemainingTime - this.idleTimerMinutes * 60
          );
        }
        this.ref.markForCheck();
      }
    );
  }
  ngOnDestroy(): void {
    this.idleTimerSubscription.unsubscribe();
    this.refreshTimerSubscription.unsubscribe();
  }
}
