import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ResponseMessageType } from 'src/app/common/enum/responseMessageType';
import { ResponseModel } from 'src/app/common/models';
import {
  AuthService,
  NotificationService,
  UsersService,
} from 'src/app/services';
import appConstant from '../../../assets/app-text.json';
@Injectable({
  providedIn: 'root',
})
export class VerifyUserResolverService implements Resolve<any> {
  constructor(
    private userService: UsersService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private router: Router
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.queryParams.params) {
      this.notificationService.error(appConstant.INVALID_URL_MESSAGE);
      this.router.navigate(['/home']);
    }
    return this.userService.validateUser(route.queryParams.params).subscribe(
      (response: ResponseModel) => {
        this.authService.clearCookies();
        if (!!response) {
          if (response.message === ResponseMessageType.SUCCESS) {
            this.authService.setChangePasswordCookies(response.data);
          } else if (response.message === ResponseMessageType.ERROR) {
            this.router.navigate(['/home']);
          }
        } else {
          this.router.navigate(['/home']);
        }
      }
    );
  }
}
