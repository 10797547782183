
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import config from '../../assets/config.json';
import { baseURL, configJSON } from '../appURLConfig';
import { ResponseMessageType } from '../common/enum/responseMessageType';
import { DataCenterModel, DataCenterResponseModel } from '../common/models';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class DataCenterListService {
  dataCenterServiceSubject = new Subject<any>();
  public clientDataCenterList: DataCenterModel[];
  private catalogApis: string[] =
  [config.validateAdminUserCatalog,
    config.getUserVerificationCatalog,
    config.updatePasswordCatalog,
    config.forgotPasswordCatalog,
    config.generateAdminCodeCatalog,
    config.refreshTokenCatalog,
    config.dataCenterCatalog,
    config.getAdminUserListCatalog,
    config.addUpdateAdminUserCatalog
  ];
  constructor(private http: HttpClient, private authService: AuthService) {
  }
  getUpdatedRequestURL(requestURL: URL) {
    const requestURLPathname = requestURL.pathname.toLocaleLowerCase();
    const objBaseURL = new URL(baseURL);
    let evaluatedURL = objBaseURL.origin;

    if (this.catalogApis.includes(requestURLPathname)) {
      evaluatedURL = objBaseURL.origin;
    } else {
     evaluatedURL = requestURL.origin;

    }
    return evaluatedURL + requestURL.pathname + requestURL.search;
  }

  public async setClientDataCenterList() {
       const result = await this.http.post<DataCenterResponseModel>(
      configJSON.getDataCenter,
      {},
      {withCredentials: true}
     ).toPromise();
       if (result && result.message === ResponseMessageType.SUCCESS) {
        this.clientDataCenterList = result.data;
      } else {
        //  TODO : what to do when no datacenter????
      }
  }
  public async getClientDataCenterList() {
    if (!this.clientDataCenterList || this.clientDataCenterList.length < 1) {
        await this.setClientDataCenterList();
    }
    return this.clientDataCenterList;
  }

  public getDataCenterUrl(dataCenterGuid: string) {
  let dataCenterUrl: string = '';
  const dataCenter = this.clientDataCenterList.filter(datacenter => datacenter.dataCenterGuid === dataCenterGuid);
  if (dataCenter.length > 0) {
      dataCenterUrl = dataCenter[0].url;
    }
  return dataCenterUrl;
  }
}
