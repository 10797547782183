import { DataCenterModel } from './dataCenterModal';

export class IManageConnectionModel {
  connectionGuid: string;
  connectionName: string;
  iManageServerURL: string;
  connectionAccessType: string;
  iManageLibraries: LibraryList[];
  dataCenterGuid: string;
  dataCenterName: string;
  iManageUserId: string;
  iManagePassword: string;
  libraries: string[];
  librarieNames: string[];
  isEnabled: boolean;
  accessPeriodStart: string;
  accessPeriodEnd: string;
  lastSynchronizationDate: string;
  isConnectionStateActive: boolean;
  dataCenterInfo: DataCenterModel[];
  taskType: string;
  synchronizationStatus: string;
  isPasswordUpdated: boolean;
  constructor() {
    this.connectionGuid = '';
    this.connectionName = '';
    this.dataCenterGuid = '';
    this.iManageServerURL = '';
    this.iManageUserId = '';
    this.iManagePassword = '';
    this.libraries = [];
    this.isEnabled = false;
    this.dataCenterInfo = [];
    this.synchronizationStatus = 'NeverRun';
  }
}

export class LibraryList {
  name: string;
  number: number;
  libraryGuid: string;
  isSelected: boolean;
}
export class IManageTestConnectionModel {
  iManageUserId: string;
  iManagePassword: string;
  iManageServerURL: string;
  connectionGuid: string;
  isNewConnection: boolean;
  isPasswordUpdated: boolean;
}

export class ConnectionStateModel {
  connectionGuid: string;
  imanageLibraries: LibraryList[];
  userAccessCodeId: number;
  userAccessCode: string;
  isConnectionStateActive: boolean;
  connectionAccessType: string;
}

export class ConnectionStateChangeModel {
  connectionGuid: string;
  isEnabled: boolean;
  dataCenterGuid: string;
}
