import { SuspendUpdateModel } from '.';
import { ErrorResponseModel } from './responseModel';

export class SchedulingResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: SchedulingConfigModel[] = [];
  // This class contains generic datatype property it may contain variety of data as well as null
  dataEnumerable: any[] = [];
  errors: ErrorResponseModel[] = [];
}

export class SchedulingConfigModel {
  iManageConnectionGuid: string;
  retentionDays: number = 0;
  isUpdateSchedule: boolean;
  updateDurationType: string;
  updateDuration: number = 0;
  suspendUpdates: SuspendUpdateModel[] = [];
  constructor() {}
}
