import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthGuard } from './core/guards/auth-gaurd';
import { LoginResolverService } from './core/guards/login-resolver.service';
import { VerifyUserResolverService } from './core/guards/verify-user-resolver.service';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    resolve: { data: LoginResolverService },
  },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'connectionsconfiguration',
    loadChildren:
      () => import('./connections-configuration/connections-configuration.module').then(m => m.ConnectionsConfigurationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'setpassword',
    component: ChangePasswordComponent,
    resolve: { userData: VerifyUserResolverService },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
