import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FilterPipe } from '../common/pipe/filter.pipe';
import { GrdFilterPipe } from '../common/pipe/grd-filter.pipe';
import { LocalDatePipe } from '../common/pipe/locale.time';
import { OrderbyPipe } from '../common/pipe/orderby.pipe';
import { UpdatedGridComponent } from '../users/updated-grid/updated-grid.component';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { AppTimerComponent } from './app-timer/app-timer.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { CustomDatePickerHeaderComponent } from './custom-date-picker-header/custom-date-picker-header.component';
import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';
import { CustomTimePickerComponent } from './custom-time-picker/custom-time-picker.component';
import { DateTimePickerDirective } from './date-time-picker/date-time-picker.directive';
import { HavenEventDirective } from './haven-event.directive';
import { HeaderComponent } from './header/header.component';
import { InputTextTrimDirective } from './input-text-trim.directive';
import { MenuComponent } from './menu/menu.component';
import { MultiselectDropdownComponent } from './multiselect-dropdown/multiselect-dropdown.component';
@NgModule({
    declarations: [
        HeaderComponent,
        AppLoaderComponent,
        ConfirmationDialogComponent,
        MenuComponent,
        HavenEventDirective,
        MultiselectDropdownComponent,
        FilterPipe,
        OrderbyPipe,
        LocalDatePipe,
        ConfirmationModalComponent,
        GrdFilterPipe,
        AppTimerComponent,
        UpdatedGridComponent,
        InputTextTrimDirective,
        DateTimePickerDirective,
        CustomDatePickerComponent,
        CustomDatePickerHeaderComponent,
        CustomTimePickerComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatNativeDateModule,
        MatInputModule,
        NgxMaterialTimepickerModule,
    ],
    providers: [],
    exports: [
        HeaderComponent,
        AppLoaderComponent,
        UpdatedGridComponent,
        ConfirmationDialogComponent,
        MenuComponent,
        MultiselectDropdownComponent,
        FilterPipe,
        OrderbyPipe,
        AppTimerComponent,
        ConfirmationModalComponent,
        GrdFilterPipe,
        InputTextTrimDirective,
        DateTimePickerDirective,
        FormsModule,
        LocalDatePipe,
        CustomDatePickerComponent,
        CustomTimePickerComponent,
        ReactiveFormsModule,
    ]
})
export class SharedModule {}
