import lodash from 'lodash';
import { userType } from '../enum/userType';

export default class Utils {
  static encryptData(data: any): string {
    return window.btoa(data);
  }

  static decryptData(data: string): any {
    return atob(data);
  }

  static getUserRole(type: string) {
    const availableUserRole = userType;
    if (availableUserRole.hasOwnProperty(type.toUpperCase())) {
      return userType[type.toUpperCase()];
    } else {
      return availableUserRole.USER;
    }
  }
  // deepClone is generic method to clone any typye of object
  // dataObject can be of defferent model type
  static deepClone(dataObject: any) {
    return lodash.cloneDeep(dataObject);
  }
}
