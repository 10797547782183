import { FormGroup } from '@angular/forms';
import { UserModel } from '../models';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function MustNotMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustNotMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (matchingControl.value !== '' && control.value.toLowerCase().trim() === matchingControl.value.toLowerCase().trim()) {
      matchingControl.setErrors({ mustNotMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function findPrimaryEmail(controlName: string, adminList: UserModel[] , actionType: boolean) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    if (control.status === 'VALID' && !actionType) {
    const checkvalue = adminList.filter(admin => admin.primaryEmailAddress === control.value);
    if (checkvalue.length > 0) {
      control.setErrors({alreadyExist: true});
    } else {
      control.setErrors(null);
    }
  }
};
}
