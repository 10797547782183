import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderby',
})
export class OrderbyPipe implements PipeTransform {
  transform(records: Array<any>, args?: any): any {
    // tslint:disable-next-line:only-arrow-functions
    return records.sort ( function( a: any, b: any ) {
      if ( (a[args.property]).toString().toLowerCase() < (b[args.property]).toString().toLowerCase()) {
        return -1 * args.direction;
      } else if ((a[args.property]).toString().toLowerCase() > (b[args.property]).toString().toLowerCase()) {
        return 1 * args.direction;
      } else {
        return 0;
      }
    });
  }
}
