import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import {
  AuthService,
  NotificationService,
  UsersService,
} from 'src/app/services';
@Injectable({
  providedIn: 'root',
})
export class LoginResolverService implements Resolve<any> {
  constructor(
    private authService: AuthService
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.authService.clearCookies();
    return true;
  }
}
