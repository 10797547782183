import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ResponseMessageType } from 'src/app/common/enum/responseMessageType';
import { AuthService, NotificationService } from 'src/app/services';
import appConfig from '../../../assets/app-text.json';
import configUrl from '../../../assets/config.json';
import { configJSON } from '../../appURLConfig';
import { DataCenterListService } from '../../services/data-center-list.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  apiByPassExceptionList: string[] = [configUrl.getUserData, configUrl.saveUserData];
  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private dataCenterListService: DataCenterListService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const urlObject = new URL(request.url);

    const urlObjectWithParam = new URL(request.urlWithParams);

    const isUpdatePasswordUrl =
      request.url.indexOf(configJSON.updatePassword) !== -1;
    const iManageUserId = isUpdatePasswordUrl
      ? this.authService.getchangePasswordData().iManageUserId
      : this.authService.getIManageUserId();
    const clientId = isUpdatePasswordUrl
      ? this.authService.getchangePasswordData().clientGuid
      : this.authService.getClientId();
    const userId = isUpdatePasswordUrl
      ? this.authService.getchangePasswordData().userGuid
      : this.authService.getUserId();
    const userEmail = isUpdatePasswordUrl
      ? this.authService.getchangePasswordData().primaryEmailAddress
      : this.authService.getUserEmail();
    const userName = isUpdatePasswordUrl
      ? this.authService.getchangePasswordData().loggedInName
      : this.authService.getUserName();
    const userRoleName = isUpdatePasswordUrl
      ? this.authService.getchangePasswordData().userRoleName
      : this.authService.getUserRole();
    if (userId) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          IManageUserId: `${iManageUserId}`,
          UserGuid: `${userId}`,
          ClientGuid: `${clientId}`,
          UserFullName: `${userName}`,
          EmailAddress: `${userEmail}`,
          UserRoleName: `${userRoleName}`,
        },
      });
    }
    const updatedURL = {
      urlWithParams: this.dataCenterListService.getUpdatedRequestURL(urlObjectWithParam),
      url: this.dataCenterListService.getUpdatedRequestURL(urlObject)
    };
    request = Object.assign(request, updatedURL);
    if (this.apiByPassExceptionList.includes(urlObject.pathname)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      tap((result: any) => {
        if (
          result &&
          result.body &&
          result.body.message === ResponseMessageType.ERROR
        ) {
          const userMessage = result.body.errors[0].userMessage;
          this.notificationService.error(userMessage);
          if (result.body.httpStatus === 401) {
            this.authService.logout();
          }
        }
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.notificationService.error(appConfig.USR_LGN_DNY_MSG);
          this.authService.logout();
          return throwError(err);
        }
        this.notificationService.error(appConfig.SERVER_DOWN_MESSAGE);
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
