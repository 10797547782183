import { Directive, HostListener } from '@angular/core';
import { TokenService } from '../services/token.service';

@Directive({
  selector: '[havenEvent]',
})
export class HavenEventDirective {
  constructor(private tokenService: TokenService) {}
  @HostListener('click', ['$event'])
  customEventHandler(event: any) {
    this.tokenService.checkTokenValidity();
  }
}
