export class ResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  // This is a generic class contains generic datatype property it may contain variety of data as well as null
  data: any;
  // This class contains generic datatype property it may contain variety of data as well as null
  dataEnumerable: any[] = [];
  errors: ErrorResponseModel[] = [];
}
export class ErrorResponseModel {
  userMessage: string;
  errorCode: number;
  devMessage: string;
  path: string;
}
