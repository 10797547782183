import { Directive } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  Validator,
  ValidatorFn,
} from '@angular/forms';
@Directive({
  selector: '[haven-passwordValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordValidatorDirective,
      multi: true,
    },
  ],
})
export class PasswordValidatorDirective implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = this.passwordValidator();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
  passwordValidator(): ValidatorFn {
    return (c: FormControl) => {
      const isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,15}$/.test(
        c.value
      );
      if (isValid) {
        return null;
      } else {
        return {
          passwordValidator: {
            valid: false,
          },
        };
      }
    };
  }
}

// custom validator to check the password thus having any
export function validatePassword(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.errors && control.errors.required) {
      // return if another validator has already found an error on the control
      return null;
    }
    // set error on control if validation fails
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,15}$/.test(
        control.value
      )
    ) {
      return { validatePassword: true };
    }
    return null;
  };
}
// custom validator to validate the password with seperate criteria thus having any
export function sequentiallyValidatePassword(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // set error on control if validation fails
    if (control && control.value !== '') {
      return {
        validateMinLength: /^(?=.*).{8,}$/.test(control.value),
        validateMaxLength: /^(?=.*).{8,15}$/.test(control.value),
        validateLowerCase: /^(?=.*[a-z])/.test(control.value),
        validateUpperCase: /^(?=.*[A-Z])/.test(control.value),
        validateNumeric: /^(?=.*[0-9])/.test(control.value),
        validateNonAlphaNumeric: /^(?=.*[!@#\$%\^&\*])/.test(control.value),
      };
    }
    return null;
  };
}
