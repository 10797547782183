<div class="col-lg-12 multiselect_div" [ngClass]="isShowlibraryList ? 'arrow-down':'arrow-up'" tabindex="0"  (click)="onShowListPopup(isShowlibraryList)" >
  <input type="text" disabled  [value]="value" [id]="id">
</div>

<div *ngIf="isShowlibraryList" id="muiltiselect" class="options_div">
  <div *ngFor="let list of dataList; let rowIndex = index" (click)="list.isSelected=!list.isSelected; onSelectListValue(list)" class="options">
    <div id="listData_rowIndex" class="float-left col-lg-1 checkbox-container">
      <input type="checkbox" [checked]="list.isSelected" />
      <span class="checkmark"></span>
      </div>
    <div class="col-lg-11 options-text">{{list.text}}</div>
  </div>
</div>