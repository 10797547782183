import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import modalConfig from '../../assets/modal-text.json';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  public subject = new Subject<any>();
  constructor() {}
  okCancelBasicConfirm(
    title: string,
    messageTitle: string,
    message: string,
    btnOkText: string,
    btnCancelText: string,
    okFunc: () => void,
    cancelFunc: () => void
  ) {
    this.setOkCancelBasicConfirmation(
      title,
      messageTitle,
      message,
      btnOkText,
      btnCancelText,
      okFunc,
      cancelFunc
    );
  }
  setOkCancelBasicConfirmation(
    title: string,
    messageTitle: string,
    message: string,
    btnOkText: string,
    btnCancelText: string,
    okFunc: () => void,
    cancelFunc: () => void
  ) {
    const that = this;
    this.subject.next({
      type: modalConfig.CONFIRM_TYPE_OK_CANCEL,
      title,
      messageTitle,
      message,
      btnOkText,
      btnCancelText,
      okFunc() {
        that.subject.next(); // this will close the modal
        okFunc();
      },
      cancelFunc() {
        that.subject.next();
        cancelFunc();
      }
    });
  }
  addEditIManageconnection(okFunc: () => void,
                           cancelFunc: () => void) {
    const that = this;
    this.subject.next({
      type: modalConfig.ADD_EDIT_IMANAGE_CONNECTION,
      okFunc() {
        that.subject.next(); // this will close the modal
        okFunc();
      },
      cancelFunc() {
        that.subject.next();
        cancelFunc();
      }
    });
  }
  clearMessage() {
    return this.subject.next();
  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
 }
