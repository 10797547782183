import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { isProduction } from '../../appURLConfig';
import { AuthService } from '../../services';
@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private authService: AuthService) { }
  // handle any variety of errors
  handleError(error: any) {
    if (!isProduction) {
      // tslint:disable-next-line: no-console
      console.log('Error:', error);
    }
    return throwError(error);
  }
}
