import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomDatePickerHeaderComponent } from '../custom-date-picker-header/custom-date-picker-header.component';

const moment = require('moment');

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'haven-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDatePickerComponent implements OnInit {
  customHeader: any;
  @Input()
  inputDate: Date;
  @Input()
  placeHolderText: string = '';
  @Input()
  date: any;
  serializedDate: any;
  minDate: any;
  @Output() dateChanged = new EventEmitter();
  constructor() {
    this.customHeader = CustomDatePickerHeaderComponent;
  }

  ngOnInit() {
    const currentDate = new Date();
    this.minDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    this.date = new Date(this.inputDate);
  }
  change(dateEvent: any) {
    this.dateChanged.emit(dateEvent.value.format(MY_FORMATS.display.dateInput));
  }
}
