import { Component, OnInit } from '@angular/core';
import { AuthService, DataCenterListService, LoginService } from 'src/app/services';

@Component({
  selector: 'haven-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loggedInUser: string;
  constructor(private authService: AuthService, private dataCenterListService: DataCenterListService, private loginService: LoginService) {}

  ngOnInit() {
    this.loggedInUser = this.authService.getUserName();
  }
  onLogout() {
    this.loginService.logout().subscribe(
      (result) => {
        this.authService.deleteCookies();
        this.authService.logout();
      }
    );
  }
}
